<template>
  <section class="section main-section">
    <h4 class="section__title section__title_centered mb-1">
      Детальная информация по балансу
    </h4>
    <GoBackIcon back-route-name="main.reports" />
    <div class="q-my-md q-mx-auto q-mb-lg" style="width: 250px">
      <q-input
        v-model="bin"
        class="q-mb-lg"
        label="ИИН/БИН"
        hint="Введите БИН"
        mask="### ### ### ###"
      ></q-input>
      <CustomDatePicker :clearable="true" @datePicked="setDates" />
      <BaseButton
        filled
        type="primary"
        html-type="submit"
        class="generate-report__button q-mt-lg"
        small
        block
        :loading="isLoading"
        :disabled="!isBinFilledCorrectly"
        @btn-click="getBalanceInfo"
      >
        Запросить
      </BaseButton>
    </div>
    <div v-if="result.length">
      <div class="flex justify-end q-mb-lg q-pr-md">
        <BaseButton
          filled
          type="primary"
          small
          :loading="isPdfLoading"
          @click="generatePdf"
          >Скачать данные
        </BaseButton>
      </div>
      <q-table
        :columns="columns"
        :rows="result"
        row-key="name"
        hide-no-data
        style="width: 100%"
      />
    </div>
  </section>
</template>

<script>
import { defineComponent, ref, reactive, computed } from "vue";
import GoBackIcon from "@/components/icon-components/GoBackIcon";
import CustomDatePicker from "@/components/common/DatePicker";
import BaseButton from "@/components/common/BaseButton";
import reportsApi from "@/api/reports.api";
import { formatBlobErrorToMsg } from "@/helpers/utils/format.utils";
import { AxiosResponse } from "axios";
import { downloadBlob } from "@/helpers/utils/download.utils";
import { storesApi } from "@/api/stores.api";

export default defineComponent({
  name: "BalanceInfoPage",
  components: { BaseButton, CustomDatePicker, GoBackIcon },
  setup() {
    const bin = ref("");
    const dates = reactive({
      from: "",
      to: "",
    });
    const isPdfLoading = ref(false);

    const isLoading = ref(false);
    const isDatePickerDisabled = ref(false);

    const isBinFilledCorrectly = computed(() => {
      return bin.value.length === 15;
    });

    const columns = [
      {
        name: "code",
        align: "left",
        label: "Лицевой счет",
        field: "code",
      },
      {
        name: "current_balance",
        align: "center",
        label: "Текущий баланс",
        field: "current_balance",
      },
      {
        name: "activated_balance",
        align: "right",
        label: "Использованная сумма",
        field: "activated_balance",
      },
    ];

    const result = ref([]);

    const setDates = (pickedDates) => {
      if (!pickedDates) {
        dates.from = "";
        dates.to = "";
        return;
      }
      dates.from = pickedDates.start.toISOString().split("T")[0];
      dates.to = pickedDates.end.toISOString().split("T")[0];
    };

    const generatePdf = async () => {
      const payload = {
        bin: bin.value,
        payload: result.value,
      };
      isPdfLoading.value = true;

      try {
        const response = await storesApi.generateUsersBalancePdf(payload);
        const fileName = `${bin.value}_balance-info`;
        downloadBlob(response, fileName);
      } catch (e) {
        await formatBlobErrorToMsg(e);
      } finally {
        isPdfLoading.value = false;
      }
    };

    const getBalanceInfo = async () => {
      if (isLoading.value) return;
      const queryParams = {};
      queryParams.bin = bin.value.replaceAll(" ", "");
      if (dates.from) {
        queryParams.from = dates.from;
        queryParams.to = dates.to;
      }
      try {
        isLoading.value = true;
        isDatePickerDisabled.value = true;
        const { data } = await reportsApi.getUsersBalanceInfo(queryParams);
        result.value = data;
      } finally {
        isDatePickerDisabled.value = false;
        isLoading.value = false;
      }
    };

    return {
      bin,
      dates,
      isPdfLoading,
      isLoading,
      isDatePickerDisabled,
      isBinFilledCorrectly,
      columns,
      result,
      setDates,
      getBalanceInfo,
      generatePdf,
    };
  },
});
</script>
